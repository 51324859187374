$grey-blue: #323841;
$dark-grey-blue: #262b31;
$light-grey-blue: #4d5766;
$medium-blue: #1e7999;
$accessible-blue: #0056b2;
$white: #fff;
$green: #007a2f;
$green-gradient: #006833;
$yellow: #fec93e;
$yellow-gradient: #b1a700;
$red: #df1f26;
$red-gradient: #892920;
$women-grey: #939597;
$women-grey-gradient: #262626;
$dark-grey: #d1d2d4;
$dark-grey-accessible: #585859;
$light-grey: #efefef;
$black: #000;
$muted: #40455e;
$muted-gradient: #40455e;
$primary:  #415778;
$alt-primary: #4970a9;
$font-primary: 'Open Sans', sans-serif;
$font-condensed: 'Open Sans Condensed', sans-serif;
