/* stylelint-disable scss/at-extend-no-missing-placeholder */
@import 'theme';

html,
body {
  overflow: hidden;
  // root font size which dictates the size of the rem unit is set here in the font-size: 14px.
  // The changes made in https://git.uc.edu/UCCI/Bonfire/pull/6054 will not take effect until this style is removed.
  font-size: 14px;
}

.modal-backdrop {
  background: rgba(0,0,0,0.5);
}

// input fields

.form-control {
  @extend .form-control;
  height: 2.85rem;
  border: 2px solid $grey-blue;
  background-color: $white;
  border-radius: 3px;
}

a {
  @extend a;
  color: $medium-blue;

  &:hover,
  &:focus {
    color: $dark-grey-blue;
  }
}

.btn {
  @extend .btn;
}

.btn-custom {
  background-color: $medium-blue;
  border: 1px solid transparent;
  font-family: $font-condensed;
  text-transform: none;
  color: $white;
  border-radius: 0.25rem;

  &:hover,
  &:focus {
    color: $white;
    background: $dark-grey-blue;
  }
}

.btn-custom:disabled,
.btn-custom[ disabled ] {
  opacity: 0.5;
  background: $medium-blue;
}

.btn-primary {
  @extend .btn-custom;
  background-color: $primary;

  &:hover,
  &:focus {
    color: $white;
    background: $alt-primary;
  }
}

.btn-danger {
  @extend .btn-custom;
  background: $red;

  &:hover,
  &:focus {
    color: $white;
    background: $red-gradient;
  }

  &:disabled {
    background: $red;
  }
}

.btn-muted {
  @extend .btn-custom;
  background: $muted;

  &:hover,
  &:focus {
    color: $white;
    background: $muted-gradient;
  }
}

.btn-link {
  @extend .btn-link;
  color: $medium-blue;

  &:hover,
  &:focus {
    color: $dark-grey-blue;
  }
}

.h4-custom {
  @extend h4;
  margin-top: 0;
  margin-bottom: 0;
  color: $grey-blue;
  font-weight: bold;
  text-transform: uppercase;
  font-family: $font-condensed, serif;
}

.grid {
  height: 40vw;
}

.filter.dropdown-menu {
  background-color: $dark-grey-accessible;
  color: $white;
  border-radius: 0;
  padding: 15px;
}

.shrunk {
  min-height: 0 !important;
}

.page-number {
  display: inline-block;
  padding-top: 5px;
}

.block-content {
  min-height: 33em;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 3px;
  background: #fff;
  margin-top: 30px;
  font-family: $font-primary;
  font-size: 1.048rem;

  .overflow-content {
    overflow: scroll;
    height: 27em;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  h4 {
    @extend .h4-custom;
  }

  .dropdown-divider-custom {
    margin: 15px -15px;
    border-top: 2px solid $grey-blue;
  }

  .secondary-divider {
    border-top: 1px solid $medium-blue;
    margin-top: 15px;
  }
}

// assessment overview / caseplan details accordion font stuff

.bar {
  color: $white;
  font-family: $font-primary;
  font-weight: bold;
  font-size: 1.357rem;
  text-transform: capitalize;
}

.nav,
.carousel,
.card-title a {
  cursor: pointer;
  color: $white;
}

.card-title {
  color: $white;
  font-family: "Open Sans Condensed", sans-serif;
}

// red bar with scrolling text across top (primarily for "staging" message)

.announcements-bar {
  background-color: $red;
  margin-left: -15px;
  margin-right: -15px;
  color: $white;
  height: 4vh;
  font-weight: bolder;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;

  a {
    color: $white;
    text-decoration: underline;
    font-style: italic;
  }

  p {
    margin-top: 1vh;
    display: inline-block;
    padding-left: 40%;
    animation: marquee 120s linear infinite;
  }
  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(-100%, 0);
    }
  }
}

.pagination {
  @extend .pagination;
  color: $medium-blue;
}

.page-item {
  @extend .page-item;
  color: $medium-blue;
}

.page-item.active a {
  @extend .page-item;
  background-color: $medium-blue !important;
  border-color: $medium-blue !important;
}

.page-link {
  @extend .page-link;
  color: $medium-blue;
}

.avatar-thumbnail {
  height: 30px;
  width: 30px;
}

label {
  @extend label;
  font-weight: bold;
}

legend {
  font-size: 1.048rem;
  font-weight: bold;
}

.notification-icon {
  margin: auto;
  font-size: 1rem;
  margin-top: 0;
  font-weight: bold;
  position: relative;

  &:hover {
    cursor: pointer;
  }
}

.tooltip-no-arrow {
  padding: 0 0 0 0 !important;

  .arrow {
    display: none;
  }
}

.tooltip-inner {
  max-width: 275px !important;
  padding: 10px !important;
  background: $grey-blue !important;
}

// little pointer arrow on the bootstrap tooltip - LEFT

.bs-popover-left .arrow:after {
  border-left-color: $grey-blue !important;
}
// little pointer arrow on the bootstrap tooltip - RIGHT

.bs-popover-right .arrow:after {
  border-right-color: $grey-blue !important;
}
// little pointer arrow on the bootstrap tooltip - TOP

.bs-popover-top .arrow:after {
  border-top-color: $grey-blue !important;
}
// little pointer arrow on the bootstrap tooltip - BOTTOM

.bs-popover-bottom .arrow:after {
  border-bottom-color: $grey-blue !important;
}

.error-popover-custom {
  .arrow:after {
    border-right-color: #f7f7f7 !important;
  }
}

.avatar-placeholder {
  background: $grey-blue;
}

circle {
  cursor: pointer;
}

tags-input .tags {
  border: none;
}

tags-input .tags.focused {
  border: none;
}

.hour-container {
  td > input,
  select {
    &.weekday {
      width: 100%;
    }
    width: 30%;
    float: left;
    margin-right: 5px;
  }
}

.modal-title {
  @extend .h4-custom;
}

.modal-variant {
  .modal-dialog {
    top: 30%;
    transform: translate(0%, -50%) !important;
  }
}

@-moz-document url-prefix() {
  .ff-fix {
    padding-bottom: 1cm;
    overflow: scroll;
    border: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.module-header-custom {
  display: flex;
  flex-direction: row;
  width: 100%;

  h4 {
    @extend .h4-custom;
    min-width: fit-content;
    align-self: flex-end;
  }

  > div.custom-width {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

// Bootstrap 4 show class in place of the BS3 in

.fade.show {
  opacity: 1;
}

.pie-slice {
  fill: #fff;
  font-size: 0.857rem;
  cursor: pointer;
  text-shadow: 0 1px #000;
}

.dc-chart g.row text {
  fill: #fff;
  font-size: 0.857rem;
  cursor: pointer;
  text-shadow: 0 1px #000;
}

// Placeholder text in react-select, default is muted and non-accessible

/* stylelint-disable selector-class-pattern */

.react-select__placeholder {
  @extend .react-select__placeholder;
  font-weight: normal;
  color: hsl(0,0%,20%) !important;
}

// see above.  Also, makes text bold when an item has been selected

.react-select__single-value {
  @extend .react-select__single-value ;
  font-weight: bold;
  color: hsl(0,0%,20%) !important;
}

.react-select__control {
  @extend .react-select__control;
  border: 2px solid $grey-blue !important;
}

.react-select__dropdown-indicator {
  @extend .react-select__dropdown-indicator;
  color: $grey-blue !important;
}

.react-select__indicator-separator {
  @extend .react-select__indicator-separator;
  background-color: $grey-blue !important;
}

.react-select__clear-indicator {
  @extend .react-select__clear-indicator;
  color: $grey-blue !important;

  &:hover,
  &:focus {
    color: $red !important;
  }
}

.react-select__option {
  @extend .react-select__option ;
  font-weight: normal;
  color: black !important;
}

.react-select__option--is-selected {
  @extend .react-select__option--is-selected;
  font-weight: normal;
  color: white !important;
}

.react-select__multi-value {
  font-weight: normal;
}

/* stylelint-enable selector-class-pattern */

// 'X" button on lists and modals, bootstrap default is muted and non-accessible

.close {
  @extend .close;
  color: $grey-blue;
  opacity: 1 !important;
  font-size: 1.714rem;
  text-shadow: none;

  &:hover {
    color: $red;
  }
}

.version {
  color: #c9c9c9;
}

a.yellow-hover:hover,
a.yellow-hover:focus {
  p,
  span,
  div {
    color: $yellow;
  }
  text-decoration: none;
}

button.yellow-hover:hover,
button.yellow-hover:focus {
  p,
  span,
  div {
    color: $yellow;
  }
}

.required-field:before {
  content: "*";
  color: $red;
}

.skip-navigation-link {
  opacity: 0;
  font-weight: bold;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  color: black;

  &:focus {
    opacity: 1;
  }
}

@media screen and (max-width: 900px) {
  .module-header-custom {
    flex-wrap: wrap;

    .btn-custom {
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
    }
  }
}

.text-shift {
  transition: all 0.15s ease-in-out;
}

.text-hover-button:hover,
.text-hover-button:focus-visible {
  .text-shift {
    padding-left: 10px;
  }
}

.modal-dialog-scrollable .modal-content {
  overflow-y: scroll;
}

.edit-icon {
  &:hover,
  &:focus {
    color: $medium-blue;
    cursor: pointer;
  }
}