.notification-danger {
  border-left: 8px solid #dc3545;
}

.notification-item.notification-danger {
  background-color: #b4001a;
}

.notification-success {
  border-left: 8px solid #28a745;
}

.notification-item.notification-success {
  background-color: #006702;
}

.notification-item.notification-warning {
  .notification-content {
    .notification-message,
    .notification-title {
      color: black;
    }
  }
}